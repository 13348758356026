@charset "utf-8";
@font-face {
  font-family: "Breymont Bold";
  src: url("/fonts/Breymont-Bold.woff2") format("woff");
}
@font-face {
  font-family: "Breymont Light";
  src: url("/fonts/Breymont-Light.woff2") format("woff");
}
@font-face {
  font-family: "Aarielle Bold";
  src: url("/fonts/Aarielle-Bold.woff") format("woff");
}
@font-face {
  font-family: "Aarielle Regular";
  src: url("/fonts/Aarielle-Regular.woff") format("woff");
}
@font-face {
  font-family: "Aarielle Thin";
  src: url("/fonts/Aarielle-Thin.woff") format("woff");
}
* {
  margin: 0px;
  padding: 0px;
}
html {
  overflow-x: hidden;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
  box-shadow: 0 0 0 30px #000 inset !important;
}
input:autofill {
  text-fill-color: #fafafa !important;
}

@keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}

input:autofill {
  animation-name: autofill;
  animation-fill-mode: both;
}

#root {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
}
.container--fluid {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* Smart phone screens */
.back {
  font-family: "Comfortaa", sans-serif;
  position: absolute;
  left: 5%;
  top: 30px;
  text-decoration: none;
  color: white;
  background-color: rgba(0, 0, 0, 0);
}

canvas {
  margin: 0px auto;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
}

body,
html {
  background-color: #000;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  position: relative;
}
body.home-body {
  /* background: url(/assets/img/main-bg.gif); */
}
h1,
h2,
h3,
h4,
p {
  width: 100%;
  font-family: "Avenir", serif;
}
.loader {
  position: absolute;
  display: flex;
  height: 100%;
  min-height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: #000;
  /* padding-top: 38.1vh; */
}
.loader > img {
  width: 36.8%;
  height: auto;
}
.container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  min-height: 92%;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}
.container--home {
  height: auto;
  min-height: 92%;
  background: #000000ba;
  height: 100%;
  position: relative;
}
.main-header {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  width: 100%;
  height: auto;
  padding: 0;
  z-index: 99;
}
.logo-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding-left: 8px;
}
.logo-container > a {
  font-family: "Breymont Light", sans-serif;
  font-size: 16px;
  color: #fafafa;
  text-decoration: none;
  font-size: 19px;
}
.logo-container > a > img {
  width: 100%;
  height: auto;
}
.logo {
  max-width: 60%;
  height: auto;
}
h1 {
  font-size: 72px;
  text-align: center;
  color: #fafafa;
  /*   font-family: 'Roboto Mono', monospace; */
  /* font-family: 'Abril Fatface', cursive; */
  /* font-family: 'Monoton', cursive; */
  /*   text-stroke-width: 1px;
   text-stroke-color: black; */
  font-family: "Amiri", serif;
  /* font-family: 'Italiana', serif; */
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000;
}

.nav-container {
  display: none;
  flex-flow: row wrap;
  justify-content: flex-end;
  height: fit-content;
  padding: 8px 0;
  visibility: hidden;
}

.nav-container > div {
  margin: 0;
  padding: 0 12px;
}

.nav-container > div > a {
  color: #fafafaab;
  text-decoration: none;
  font-size: 12px;
  font-family: "Breymont Light", sans-serif;
}
.nav-container > div > a:hover {
  color: #fafafa;
}
.about-body {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
header {
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: #fafafa;
  height: 480px;
}
header > p {
  width: 100%;
  font-size: 15px;
  font-family: "Breymont Light", sans-serif;
  margin: 4px 0;
}
header > p > strong {
  font-weight: 400;
  font-size: 16px;
  font-family: "Breymont Light", sans-serif;
}
.about-content > p {
  color: #fafafa;
  font-family: "Aarielle Regular", serif;
  padding: 2% 24px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: justify;
  font-weight: 400;
  font-size: 14px;
}
.Toastify__toast--default {
  background-color: #121212 !important;
  color: #fafafa !important;
}
.Toastify__close-button--default {
  color: #fafafa !important;
}
.release-artists {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  font-family: "Aarielle Regular", serif;
  margin-top: 2%;
}
.release-artists > span {
  margin: 0 2px;
}
.form-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: 4%;
}
.form-header,
.about-header {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 8px 0;
  justify-content: center;
  height: auto;
  font-family: "Breymont Light", sans-serif;
}
.about-header > h3,
.form-header > h3 {
  color: #fafafa;
  font-family: "Breymont Light", sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.form-group {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
}
.form-control {
  width: 100%;
  font-size: 16px;
  padding: 12px;
  /* margin: 8px; */
  background-color: transparent;
  border: 0px;
  color: #fafafa;
  border-bottom: 2px solid #fafafa;
  font-family: "Aarielle Regular", serif;
}
.button {
  text-decoration: none;
  padding: 10px 22px;
  border-radius: 136px;
  color: #fafafa;
  border: 2px solid #fafafa;
  background-color: transparent;
  width: 100%;
  font-size: 16px;
  margin: 16px 0;
  font-family: "Aarielle Regular", serif;
  transition: background-color 0.8s ease-in-out;
  transition: color, background-color 0.2s ease-in-out;
}
.button:hover {
  background-color: #fafafa;
  color: #000;
  cursor: pointer;
}
.button--secondary {
  color: #121212;
  border: 2px solid #121212;
  background-color: transparent;
  width: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.button--secondary:hover {
  background-color: #121212;
  color: #fafafa;
  cursor: pointer;
}
.button--secondary.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.tracklist {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin-bottom: 2%;
}
.tracklist > li {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 4px 0;
  width: 100%;
  font-family: "Aarielle Regular", serif;
}
.demo-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 0 4%;
}
.releases-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100%;
  width: 100%;
  height: auto;
}
.releases-container > h3 {
  font-weight: 200;
}
.releases-grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding-top: 2%;
  height: 100%;
  min-height: 100%;
  position: relative;
}
.main-footer > .links > div:first-child {
  padding-left: 0;
}
.release-container {
  height: auto;
  margin: 16px 8px;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  position: relative;
  min-height: 80px;
  align-items: center;
  padding: 4% 0;
}
.release-container > div {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.release-cover {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 320px;
  position: relative;
}
.release-cover > img {
  width: auto;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}
.release-caption {
  color: #fafafa;
  font-family: "Breymont Light", sans-serif;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  padding: 16px 0;
  font-size: 16px;
  height: fit-content;
}
.release-description {
  color: #fafafa;
  font-family: "Aarielle Regular", serif;
  font-size: 14px;
  padding: 3.2% 4%;
}
.modal--release {
}
.track-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3.2% 0;
}

.track-list > ul {
  list-style: none;
  color: #fafafa;
  font-family: "Aarielle Regular", serif;
}
.modal--release > div {
  width: 86%;
}
.release__title,
.release__tracks {
  font-family: "Aarielle Regular", serif;
}
.release__title {
  margin-left: 8%;
  padding: 2% 0;
  width: 100%;
  font-family: "Breymont Light", sans-serif;
}
.release__tracks {
  display: flex;
  flex-flow: row wrap;
  margin: 0 8%;
  padding: 2% 0;
}
.release__tracks > div {
  margin: 4% 0;
}
.release__meta {
  font-family: "Aarielle Regular", serif;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-left: 8%;
  padding-top: 6%;
  border-top: 1px solid #ffffff30;
}
.release__social-links {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 100%;
  padding: 8% 8% 0% 8%;
  font-size: 24px;
  list-style: none;
}
.release__social-links > li > a {
  color: #fafafa;
}
.release__social-links-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.about-content {
  margin-top: 2%;
  display: flex;
}
.main-footer {
  display: none;
  grid-template-columns: 64% 36%;
  width: 100%;
  background-color: #000000ad;
  padding: 4% 0;
  padding-bottom: 0;
}

.main-footer > .links {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0 12px;
  visibility: hidden;
}
.main-footer > .social {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
}
.main-footer > .links > div {
  padding: 8px 6px;
}
.main-footer > .links > div > a {
  font-size: 12px;
  color: #fafafaab;
  text-decoration: none;
  font-family: "Breymont Light", sans-serif;
  cursor: pointer;
}
.main-footer > .links > div > a:hover {
  color: #fafafa;
}
.main-footer > .social > div {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.main-footer > .social > div > a {
  color: #fafafaab;
  font-size: 15px;
}
.main-footer > .social > div > a:hover {
  color: #fafafa;
}
.main-footer > .social > div > a > img {
  height: 12px;
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;
}
.main-footer > .social > div > a > img:hover {
  opacity: 1;
}
.main-logo-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.main-logo-container > img {
  width: 32%;
  height: auto;
}

.hero-text {
  color: #fafafa;
  font-family: "Aarielle Regular", serif;
  padding: 0 8px;
  height: auto;
  margin: 0 auto;
  /* border-top: 1px solid #fafafa32; */
  /* border-bottom: 1px solid #fafafa32; */
  box-sizing: border-box;
  margin-bottom: 8px;
  align-self: flex-end;
}
.hero-text__content {
  text-align: center;
  padding: 2% 4px;
  box-sizing: border-box;
  /* border-bottom: 1px solid #fafafa8a; */
  font-size: 14px;
}
.hero-text__content:last-child {
  border: 0px;
}
.nav-control {
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  padding: 8px;
  z-index: 9;
}
.nav-control > a {
  font-size: 19px;
  cursor: pointer;
}
.nav-control__close {
  display: none;
}
.mobile-nav {
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000000;
  z-index: 9;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: center;
}

.mobile-nav > div {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.mobile-nav > div.social {
  justify-content: space-evenly;
  width: 64%;
}
.mobile-nav > div.social > div {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.mobile-nav > div.social > div > a {
  color: #fafafa;
  font-size: 20px;
}
.mobile-nav > div.social > div > a > img {
  height: 14px;
}
.mobile-nav > div > a {
  color: #fafafa;
  font-size: 16px;
  text-decoration: none;
  font-family: "Breymont Light", sans-serif;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}
.modal-content {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #121212;
  padding: 0.8rem;
  width: 88%;
  border-radius: 0.5rem;
  /* display: flex; */
  /* flex-flow: row wrap; */
  /* justify-content: center; */
  align-items: center;
}
.modal-header {
  width: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding: 5px 0;
}
.close-button {
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 32px;
  color: #3e3e3e;
  transition: color 0.2s ease-in-out;
  float: right;
}
.close-button:hover {
  /* background-color: darkgray; */
  color: #fafafa;
}
.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  z-index: 999;
  background-color: #000;
}
.register-heading {
  color: #fafafa;
  width: 100%;
  font-family: "Aarielle Regular", serif;
  font-size: 16px;
  text-align: center;
  padding: 3px 0;
}
.register-form-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.register-form {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 8% 0;
}
.release-cover {
  justify-content: center;
}
a {
  transition: color 0.2s ease-in-out;
}

.thankyou-text-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.thankyou-text-container > h2 {
  color: #fafafa;
  font-family: "Breymont Light", sans-serif;
  width: 50%;
  font-size: 20px;
}
.demos-notice-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  padding: 0 4%;
}
.demos-notice {
  color: #fafafa;
  font-family: "Aarielle Regular", serif;
  width: 88%;
  font-size: 14px;
  text-align: justify;
  opacity: 0.4;
}
.release-links {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  padding: 8px 0;
  font-family: "Aarielle Regular", serif;
  font-size: 16px;
}
.release-links > a {
  color: #fafafa;
  margin: 0 8px;
}
.release-links > a.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.release-links > a > img {
  height: 16px;
  width: auto;
}
.release-modal-heading {
  font-size: 20px;
  font-family: "Breymont Light", sans-serif;
}

/* Releases grid styles */

.og-grid {
  list-style: none;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.og-grid > li {
  display: inline-block;
  margin: 2rem;
  vertical-align: top;
  height: auto;
}

.og-grid li > a,
.og-grid li > a img {
  border: none;
  outline: none;
  display: block;
  position: relative;
  height: 240px;
  text-decoration: none;
  color: #fafafa;
  margin: 0 auto;
}

.og-grid li.og-expanded > a::after {
  top: auto;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #ddd;
  border-width: 15px;
  left: 50%;
  margin: -20px 0 0 -15px;
}

.og-expander {
  position: absolute;
  background: #ddd;
  top: auto;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-top: 0;
  text-align: left;
  height: 0;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 9999;
}

.og-expander-inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.og-close {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 4;
}

.og-close::before,
.og-close::after {
  content: "";
  position: absolute;
  width: 100%;
  top: 50%;
  height: 1px;
  background: #888;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.og-close::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.og-close:hover::before,
.og-close:hover::after {
  background: #333;
}

.og-fullimg,
.og-details {
  width: 100%;
  float: left;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 0 4%;
  padding-top: 40px;
  overflow-y: scroll;
}

.og-details {
  align-items: center;
  justify-content: flex-start;
}

.og-fullimg {
  text-align: center;
}

.og-fullimg img {
  display: inline-block;
  max-height: 64%;
  max-width: 100%;
}
.releaseCaption {
  padding: 8% 0;
  font-size: 1rem;
}

.og-details h3 {
  font-weight: 300;
  font-size: 40px;
  padding: 0 0 10px;
  margin-bottom: 10px;
}

.og-details p {
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  color: #121212;
  margin-bottom: 4%;
}

.og-details > a {
  margin: 0 8px;
}
.og-details > a:nth-child(2) {
  margin: 0 8px 0 0;
}
.og-details > a:nth-child(3) {
  margin: 0 0 0 8px;
}
.tracklist-title {
  margin-top: 8%;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 0.8%;
}
.og-loading {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ddd;
  box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  -webkit-animation: loader 0.5s infinite ease-in-out both;
  -moz-animation: loader 0.5s infinite ease-in-out both;
  animation: loader 0.5s infinite ease-in-out both;
}

@-webkit-keyframes loader {
  0% {
    background: #ddd;
  }
  33% {
    background: #ccc;
    box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ddd;
  }
  66% {
    background: #ccc;
    box-shadow: 0 0 1px #ccc, 15px 30px 1px #ddd, -15px 30px 1px #ccc;
  }
}

@-moz-keyframes loader {
  0% {
    background: #ddd;
  }
  33% {
    background: #ccc;
    box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ddd;
  }
  66% {
    background: #ccc;
    box-shadow: 0 0 1px #ccc, 15px 30px 1px #ddd, -15px 30px 1px #ccc;
  }
}

@keyframes loader {
  0% {
    background: #ddd;
  }
  33% {
    background: #ccc;
    box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ddd;
  }
  66% {
    background: #ccc;
    box-shadow: 0 0 1px #ccc, 15px 30px 1px #ddd, -15px 30px 1px #ccc;
  }
}

@media screen and (max-width: 830px) {
  .og-expander h3 {
    font-size: 32px;
  }
  .og-expander p {
    font-size: 13px;
  }
  .og-expander a {
    font-size: 12px;
  }
}

@media screen and (max-width: 650px) {
  .og-fullimg {
    display: none;
  }
  .og-details {
    float: none;
    width: 100%;
  }
}

/* End Releases Grid Styles */
/* iPhone 6/7/8 */

@media (width: 375px) {
  .loader > img {
  }
  .container {
    min-height: 90.4%;
  }
}

/* Smaller screens */

@media (max-width: 360px) {
  .main-footer > .links > div {
    padding: 8px 4px;
  }
  .loader > img {
    /* margin-top: 31.6%; */
    /* top: 49%; */
    width: 37%;
  }
  .hero-text {
    margin-top: 116%;
  }
}

/* iPhone 6/7/8  */
@media (width: 375px) {
  .loader {
  }
  .loader > img {
    /* top: 49%; */
  }
}
@media (width: 411px) {
  .loader {
  }
  .loader > img {
    /* top: 0; */
    /* top: 49%; */
  }
}
@media (width: 425px) {
  .loader {
  }
  .loader > img {
    /* top: 3.3%; */
  }
}

/* Tablets / Netbooks */
@media (min-width: 700px) {
  .nav-control {
    display: none;
  }
  .logo-container {
  }
  .main-header {
    grid-template-columns: 0.24fr 1fr;
  }
  .hero-text {
    margin-top: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .hero-text__content {
    width: 100%;
    padding: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
  .container {
    min-height: 93.6%;
  }
  .nav-container {
    display: flex;
    visibility: visible;
  }
  .nav-container > div {
    margin: 0 0;
  }
  .main-footer {
    display: grid;
  }
  .main-footer > .links {
    visibility: visible;
  }
  .nav-container > div > a {
    color: #fafafaab;
    text-decoration: none;
    font-size: 12px;
    font-family: "Breymont Light", sans-serif;
  }
  .form-container {
    width: 64%;
    margin: 0 auto;
    padding: 0;
  }
  .button {
    width: 48%;
    margin: 4% 0;
  }
  .button--secondary {
    width: auto;
  }
  .form-group {
    padding: 0 5.6%;
    margin: 16px 8px;
  }
  .about-body {
    width: 72%;
    margin: 0 auto;
  }
  .loader > img {
    /* top: 48.6%; */
  }
  .loader {
    top: 4px;
    height: 96%;
    min-height: 90%;
  }
  .about-body > header {
    padding: 32% 0;
  }
  .main-footer {
    display: grid;
    grid-template-columns: 82% 18%;
    width: 100%;
    padding: 16px 0;
  }
  .releases-grid {
    justify-content: space-evenly;
    padding: 0;
    width: 100%;
    height: -webkit-fill-available;
    align-items: center;
  }
  .release-container {
    width: 80%;
    padding: 1.6% 4%;
    grid-template-columns: 1fr 1.6fr;
    /* background-color: #9c9c9c17; */
    border-radius: 8px;
  }
  .release__social-links {
    padding: 0 2%;
  }
  .form-header,
  .about-header {
    padding: 16px 0;
  }
  .about-content {
    margin-top: 0;
  }
  .about-content > p {
    font-size: 16px;
  }
  .show-modal {
    background-color: transparent;
  }
  .modal-content {
    width: 48%;
  }
  .modal--release > div {
    width: 64%;
  }
  .og-expander-inner {
    height: 100%;
    display: grid;
    grid-template-columns: 0.64fr 1fr;
    grid-gap: 4%;
  }
  .og-details {
    padding: 4% 8px 0 0;
  }
  .og-details,
  .og-fullimg {
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  .tracklist-title {
    margin-top: 4%;
  }
  .og-details h3 {
    padding: 0;
    margin-top: 16px;
  }
  .og-expander {
    overflow: hidden;
  }
}

/* Large screens */
@media (min-width: 1000px) {
  .container--home {
    height: 96.8%;
  }
  .nav-container {
    justify-content: flex-end;
  }
  .main-logo-container > img {
    margin-top: 16px;
    width: 25%;
  }
  .logo-container > a > img {
    width: 72%;
  }
  .form-container {
    width: 40%;
  }
  .about-body {
    width: 48%;
  }
  .loader > img {
    width: 28.8%;
    /* top: 47.4%; */
  }
  .about-body > header {
    padding: 24% 0;
  }
  .logo-container > a {
    font-size: 22px;
    padding: 8px 0 6px 0;
    text-align: left;
  }
  .nav-container > div > a {
    font-size: 14px;
  }
  .main-footer > .links > div > a {
    font-size: 14px;
  }
  .main-footer > .links > div {
    padding: 4px 12px;
  }
  .about-content > p {
    padding: 4% 0;
  }
  .main-footer {
    padding: 8px 0;
    grid-template-columns: 88% 12%;
  }
  .releases-grid {
    justify-content: space-evenly;
    width: 76%;
    margin: 0 auto;
    height: -webkit-fill-available;
  }
  .release-container {
    width: 100%;
  }
  .modal-content {
    width: 32%;
  }
  .main-footer > .social > div > a {
    font-size: 17px;
  }
  #root {
    height: 95%;
  }
  .modal--release > div {
    width: 32%;
  }
}
@media (min-width: 1279px) {
  .loader > img {
    /* top: 45%; */
  }
  .container--home {
    height: 94.4%;
  }
  .release-container {
    width: 80%;
  }
}
@media (min-width: 1440px) {
  .loader > img {
    /* top: 45.2%; */
    width: 29%;
  }
  .container--home {
    height: 95%;
  }
  .release-container {
    width: 64%;
  }
}

@media (min-width: 1441px) {
  .loader > img {
    /* top: 44.6%; */
  }
}
